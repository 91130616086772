
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional, WritableSignal, inject, signal } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { AlertService } from './alert.service';
import { StorageService } from './storage.service';
import { REQUEST } from 'src/express.tokens';
import { FormGroup } from '@angular/forms';
import { isEmpty } from 'underscore';

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  private API_URL: WritableSignal<string> = signal('');
   private httpOptions = signal({
    headers: new HttpHeaders({
      Accept: 'multipart/form-data',
      "Cache-Control": "max-age=84600, public"
    })
  });

  private storage = inject(StorageService)

  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    @Optional() @Inject(REQUEST) private request: any
  ) {
    this.API_URL.set(environment.BASE_API_ENDPOINT);
    let enToken = this.request || ''
    const _user = enToken && this.storage.decryption(enToken, this.storage.USER.PASSWORD);
    this.httpOptions.set({
      headers: new HttpHeaders({
        Accept: 'multipart/form-data',
        'x-access-token': _user.token || '',
        "Cache-Control": "max-age=84600, public"
      })
    })
  }

  private formatErrors(error: any) {
    return throwError(() => new Error(error.error.message ? error.error.message : 'Something Occurred', { cause: error }));
  }

  // private formatErrorsProfile(error: any) {
  //   return throwError(() => new Error(error.error.message ? error.error.message : 'Something Occurred', { cause: error }));
  // }

  

  // GET METHOD HTTP REQUEST
  get(path: string, hasLoader: boolean = true, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${this.API_URL()}${path}`, { headers: this.httpOptions().headers.append('hasLoader', hasLoader + ''), params })
      .pipe(catchError(this.formatErrors));
  }
  // getProfile(path: string, hasLoader: boolean = true, params: HttpParams = new HttpParams()): Observable<any> {
  //   return this.http.get(`${this.API_URL()}${path}`, { headers: this.httpOptions().headers.append('hasLoader', hasLoader + ''), params })
  //     .pipe(catchError(this.formatErrorsProfile));
  // }

  getLocal(path: string, params: HttpParams = new HttpParams(), hasLoader: boolean = true): Observable<any> {
    return this.http.get(`${environment.APP_URL}${path}`, { headers: this.httpOptions().headers.append('hasLoader', hasLoader + ''), params })
      .pipe(catchError(this.formatErrors));
  }

  // POST METHOD HTTP REQUEST
  post(path: any, body: object = {}, hasLoader: boolean = true, reportProgress = false): Observable<any> {
    return this.http.post(`${this.API_URL()}${path}`, body, { headers: this.httpOptions().headers.append('hasLoader', hasLoader + ''), reportProgress, })
      .pipe(catchError(this.formatErrors));
  }



  put(path: any, body: object = {}, hasLoader: boolean = true) {
    return this.http.put(`${this.API_URL()}${path}`, body, { headers: this.httpOptions().headers.append('hasLoader', hasLoader + '') }).pipe(map((r: any) => {
    })).pipe(catchError(this.formatErrors));
  }


  delete(path: string, alert: boolean = false, params: HttpParams = new HttpParams(), hasLoader: boolean = true) {
    return this.http.delete(`${this.API_URL()}${path}`, { headers: this.httpOptions().headers.append('hasLoader', hasLoader + ''), params }).pipe(map((r: any) => {
      if (alert) {
        this.alertService.alert(r.message ? r.message : 'Success', 'success');
      }
    })).pipe(catchError(this.formatErrors));
  }

  upload(path: any, body: FormData, hasLoader: boolean = true) {
    return this.http.put(`${this.API_URL()}${path}`, body, { headers: this.httpOptions().headers.append('hasLoader', hasLoader + '') }).pipe(map((r: any) => {
    })).pipe(catchError(this.formatErrors));
  }

  // POST HTTP REQUEST WITH FILE UPLOAD
  postMultiData(path: string, file: FormData, hasLoader = true, reportProgress = false): Observable<any> {
    const httpOptionsImg = {
      headers: new HttpHeaders({
        Accept: 'multipart/form-data',
        hasLoader: hasLoader + ''
      }),
      reportProgress
    };
    return this.http.post(`${this.API_URL()}${path}`, file, httpOptionsImg)
      .pipe(catchError(this.formatErrors));
  }

  beforeSubmit(form: FormGroup): Promise<void> {
    return new Promise((resolve) => {
      Object.keys(form.value).forEach((x) => {
        if (typeof x === 'string' && !isEmpty(form.controls[x].value)) {
          form.controls[x].setValue(form.controls[x].value.trim());
        }
        resolve(form.value);
      });
    });
  }
  destroy(subscription: any): void {
    for (const e of Object.keys(subscription)) subscription[e].unsubscribe();
  }

  fetchData(payload: string): Observable<any> {
    return this.get(`/round-share/fetch?${payload}`)
  }
}
